import slick from "slick-carousel";

$(function () {
    $('[data-button-contact]').on('click touchstart',function (e) {
        e.preventDefault();
       let $this = $(this);
       let $form = $this.closest('form');
       let $value =  $this.data('button-contact');
       let $answer = $form.find('[data-answer]');
       let $listButton = $form.find('[data-list-button]');
       let $field = $form.find('[data-contact-field]').find('input');

       $listButton.find('.select').removeClass('select');
       $this.addClass('select');
       $field.val($value);
       $answer.text($value);
    });

    //слик с главной
    $('[data-instruction-slick]').slick({
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: true,
        arrows: true,
        infinite: true,
        speed: 1500,
        pauseOnFocus: true,
        responsive: [
            {
                breakpoint: 1441,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 990,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,

                }

            },
        ]
        });

    $('[data-product-slick-big]').slick({
        asNavFor: '[data-product-slick-small]',
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
    });

    $('[data-product-slick-small]').slick({
        asNavFor: '[data-product-slick-big]',
        slidesToShow: 5,
        slidesToScroll: 1,
        arrows: false,
        infinite: true,
        centerMode: true,
        focusOnSelect: true,

        responsive: [
            {
                breakpoint: 1441,
                settings: {
                    slidesToShow: 3,
                }

            },
            {
                breakpoint: 1240,
                settings: {
                    slidesToShow: 3,

                }

            },
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 4,

                }

            },
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 3,

                }

            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 3,

                }

            },
            ]

    });

    $('[data-news-slider]').slick({
        slidesToShow: 4,
        slidesToScroll: 2,
        dots: false,
        arrows: true,
        infinite: true,
        speed: 1500,
        pauseOnFocus: true,

        responsive: [
            {
                breakpoint: sizes.L,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 2,
                }

            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                }

            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }

            },
            {
                breakpoint: sizes.XS,
                settings: {
                    slidesToScroll: 1,
                    slidesToShow: 1,
                    center: true

                }

            },
        ]
    });

    $('[data-button-preloader-on]').on('click touchstart', function (e) {
        $('body').addClass('processed');
    });

    $('[data-product-checkbox]').on('click touchstart', function (e) {

        let $priceElement =  $('[data-price-value]');

        if ($('[data-product-form-contact]').hasClass('hidden')){
            $('[data-product-form-contact]').removeClass('hidden');

            let price =  $priceElement.data('price-value');
            let changeColor =  $priceElement.data('color-change');

            $priceElement.text(price+changeColor);

        }else{
            $('[data-product-form-contact]').addClass('hidden');
            $($priceElement).text($priceElement.data('price-value'))
        }

    });

    //функция задержки
    let delay = (function(){
        let timer = 0;
        return function(callback, ms){
            clearTimeout (timer);
            timer = setTimeout(callback, ms);
        };
    })();

    let $form = $('[data-form-filter]');

    $('[data-about-my-button]').on('click touchstart', function (e) {
        e.preventDefault();
       $('[data-about-my-text]').toggleClass('opened');
       $(this).toggleClass('opened');
    });

    //нажатие на теги
    $form.find('.radio-list input').on('change',function (e) {
        delay(function(){
            update();
        }, 100);
    });


    //сброс фильтра
    $('[data-filter-celar]').on('click touchstart', function (e) {
        delay(function(){
            clearFilter();
        }, 100);
    });

    //кнопка подобрать
    $('[data-filter-search]').on('click touchstart', function (e) {
        e.preventDefault();
        let $form = $(this).closest('form');
        if ($form) {
            let offsetTop = $form.offset().top;
            let height = $form.height();

            $('body,html').animate({scrollTop: offsetTop+height}, 1100);

        }

    });

    function clearFilter() {
        $('[data-input-search]').val('');
        $form.find('[data-marker]').prop('checked',false);
        $form.find('.checkbox-list input').prop('checked',false);
        update();
    }

    function update() {
        $.ajax({
            data: $form.serialize(),
        }).then((html) => {
            replaceContent(html);
        })
    }

    function replaceContent(html) {
        let $html = $(html);
        $('[data-list-catalog]').replaceWith($html.find('[data-list-catalog]'));
    }

})
